html {
  background-color: #dad8ad;
  background-image: url('./pattern.png');
  background-size: 100px;
  background-origin: padding-box;
}

.App {
  text-align: center;
  background-color: #dad8ad;
  background-image: url('./pattern.png');
  background-size: 100px;
  background-origin: padding-box;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #dad8ad;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.navbar-brand img {
  margin-right: 10px;
}

.floating {
  position: relative;
  display: inline-block;
}

